.navv{
    background-color: var( --main-color) ;
}
.navv img{
    width: 150px;
    height: auto;
    /* max-height: 100px; */
}
.navv img.logolight{
    width: 160px;

   mix-blend-mode: difference;
}







.switch {
    --button-width: 3.5em;
    --button-height: 2em;
    --toggle-diameter: 1.5em;
    --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
    --toggle-shadow-offset: 10px;
    --toggle-wider: 3em;
    --color-grey: #4d3c3c;
    --color-green: #fdfdfd;
    cursor: pointer;
   }
   
   .slider {
    display: inline-block;
    width: var(--button-width);
    height: var(--button-height);
    background-color: var(--color-grey);
    border-radius: calc(var(--button-height) / 2);
    position: relative;
    transition: 0.3s all ease-in-out;
   }
   
   .slider::after {
    content: "";
    display: inline-block;
    width: var(--toggle-diameter);
    height: var(--toggle-diameter);
    background-color: #fad8d8;
    border-radius: calc(var(--toggle-diameter) / 2);
    position: absolute;
    top: var(--button-toggle-offset);
    transform: translateX(var(--button-toggle-offset));
    box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
    transition: 0.3s all ease-in-out;
   }
   
   .switch input[type="checkbox"]:checked + .slider {
    background-color: var(--color-green);
   }
   
   .switch input[type="checkbox"]:checked + .slider::after {
    transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
    box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
   }
   
   .switch input[type="checkbox"] {
    display: none;
   }
   
   .switch input[type="checkbox"]:active + .slider::after {
    width: var(--toggle-wider);
   }
   
   .switch input[type="checkbox"]:checked:active + .slider::after {
    transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
   }
   


   .catalogus-links a , .catalogus-links-mobile a  {
    color: var(--main-text-color);
    text-decoration: none;
    padding: 5px;
    border: 0.5px solid var(--bg-inner);
    font-size: 14px;
   }
   .catalogus-links{
    display: flex   ;
    align-items: center;
    justify-content: center;
   }
   .catalogus-links a:hover , .catalogus-links-mobile a:hover{
    background-color: var(--bg-inner);
    color: var(--cube-hover-bgtop);
   }
   select{
    cursor: pointer;
    background-color: var(--main-color);
  color: var(--main-text-color);
  
   }
 
   .catalogus-nav-container{
    position: relative;
   }
.catalogus-links-mobile{
    background-color: var(--cube-hover-bgtop);
    z-index: 7;
    /* height: calc(100vh - 194px); */
    width: 200px;
    right: 0;
    flex-direction: column;
    top: 95px;
    padding: 20px;
    display: flex;
}
.menu-icon{
    color: var(--main-text-color);
    cursor: pointer;
}

@media (min-width:1000px) {
    .catalogus-links-mobile{
        display: none !important;
    }
    .menu-icon{
        display: none;
    }
}
   @media (max-width:1000px) {
    .catalogus-links{
        display: none !important;
    }
   }

   .mobile-tool-check{
    display: flex;
   }

   @media ( min-width:700px) {
    .mobile-tool-check{
        display: none!important;
    }
   }


   @media (max-width:350px) {
    .staalix-logo{
        width: 100px !important;
    }
    
   }