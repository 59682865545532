
.contact{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.801);
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    display: flex;
    align-items: center;
    
}

.login-box {
    font-family: sans-serif;
    width: 80%;
    height: 50%;
    max-width: 400px;
    padding: 40px;
    background: var( --cube-hover-bgtop);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 50%;
    color: var(--main-text-color);
text-align: center;
}

.login-box h4 {
    margin: 0 0 30px;
    padding: 0;

    text-align: center;
}

.login-box .user-box {
    position: relative;
}

.login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
color: var(--main-text-color);
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid var(--main-text-color);
    ;
    outline: none;
    background: transparent;
}

.login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    pointer-events: none;
    transition: .5s;
}

.login-box .user-box input:focus~label,
.login-box .user-box input:valid~label {
    top: -20px;
    left: 0;
    
    font-size: 16px;
}

.login-box form a {
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    color: var(--main-text-color);
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 10px;
    letter-spacing: 2px;
    border: 1px solid black;
}

/* .login-box a:hover {
    background: var(--main-color);
    border-radius: 5px;
    box-shadow: 0 0 5px var(--main-color),
        0 0 25px var(--main-color),
        0 0 50px var(--main-color),
        0 0 100px var(--main-color);
} */





.back-arrow{
    color: var(--main-text-color);
    cursor: pointer;
}

@media (max-width:450px) {
    .login-box form a{
   font-size: 10px;
    }
    .login-box .user-box input:focus~label,
.login-box .user-box input:valid~label{
    font-size: 12px;
}
.login-box .user-box input{
    font-size: 12px;
}
}
@media (max-width:320px) {
    .login-box form a{
   font-size: 8px;
    }
    .login-box .user-box input:focus~label,
.login-box .user-box input:valid~label{
    font-size: 10px;
}
.login-box .user-box input{
    font-size: 10px;
}
}