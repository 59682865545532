.dealers{
    background-color: var(--main-color);
    color: var(--main-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.dealers .dealerpopup {
    background-color: rgba(0, 0, 0, 0.863);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
}

.dealers .container h1{
    font-size: 60px;
}

.dealers .dealers-box{
    display: grid;
    grid-template-columns: repeat(auto-fill ,minmax(200px,1fr));
    gap: 40px;
}
.dealers-box .thedealer{
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dealers-box .thedealer .number{
    position: absolute;
    font-size: 245px;
    color: #c2bebe94;
    opacity: 0.5;
    top: -70px;
    left: -64px;
}
.dealers-box .thedealer:hover .number{
    opacity: 1;
    
}
.dealercontent img{
max-width: 150px;
}

.dealerinfo{
    background-color: white;
    color: black;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 15px;
    padding: 15px;
    gap: 10px;
    flex-wrap: wrap;
}
.dealerinfo .dealercontent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 650px) {
    .dealers-box .thedealer .number{
        display: none;
    }
}