.product-details{
    background-color: var(--main-color);
    overflow-x: hidden
}

.product-details .container{
    color: var(--bg-inner);
}
.product-details .name{
    font-size: 24px;
    color: var(--main-text-color);
    text-decoration:underline;
}
@media (max-width:400px) {
    .product-details .name{
font-size: 18px;

    }
}
.product-details .vertical{
/* text-orientation:mixed; */
position: absolute;
left: 0;
bottom: 0;
transform: rotate(180deg) translate(-4px);
  writing-mode: vertical-lr;
  /* color: black; */
  mix-blend-mode: difference;
}
/* .current-image-container{
    height: 100%;

} */
.current-image{
    background-color: var(--cube-hover-bgtop);
    width: 100%;
}
.product-button{
    width: 100%;
    border: 0.4px solid var(--cube-bgtop);
background-color: var(--cube-hover-bgtop);
padding: 10px;
color: var(--bg-inner);
margin-bottom: 10px;
transition: all 0.2s ease-in-out;
font-size: 17px;
font-weight: bold;
}
.product-button:hover{
    background-color: var(--main-color);
    color: var(--main-text-color);
    
}


@media (max-width:300px) {
    .product-desc{
        font-size: 12px;
    }
}






.tablee {
    /* border-collapse: collapse; */
    text-align: center;
    font-size: 12px;
    /* max-width: 100%; */
    overflow-x: scroll !important;
    max-width: 100%;
    width: 100%;
  }

  
  
 .tablee a{
    color: var(--main-text-color);
    text-decoration: none;
 }
  .tablee th,
  .tablee td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
    text-align: center;
  }
  
  .tablee th {
    background-color: #c77126 !important;
  }
  
  .tablee tr {
    background-color: var(--cube-hover-bgtop) !important;
    width: 100% !important;
  }
  .tablee .current-page  , .tablee .product-row:hover{
    background-color: var(--bg-inner) !important;
}

.tablee .current-page td a ,  .tablee .product-row:hover td a{
      color: var(--cube-hover-bgtop) !important;

  }


  .table-container{
    width: 100%;
overflow-x: scroll;

  }


  /* .table-container::-webkit-scrollbar {
    height: 8px; 
  }
  
  .table-container::-webkit-scrollbar-track {
    background-color: var(--cube-hover-bgtop) ;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background-color: var(--bg-inner);
    border-radius: 3px;
    width: 50%;
  }
  
  .table-container::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  } */