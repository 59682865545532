.products-container{
    padding: 70px;
}
.product-card{
    background-color: var(--cube-hover-bgtop);
padding: 13px 10px;
text-align: center;
border: 0.7px solid var(--bg-inner);
text-decoration: none;
position: relative;
min-height: 254px;
max-width: 400px !important;
display: grid;
flex-direction: column;
justify-content: center;
}

.product-card .product-name{
    color: var(--bg-inner);
    text-align: left;
    max-width: 100%;
justify-self: flex-start
}
@media (max-width: 350px) {
    .products-container{
        padding: 20px;
    }
    .product-card .product-name{
        font-size: 14px
    }
}
.product-card img{
    width: 100%;
    max-width: 100%;
    height: auto;
    /* background-color: var(--cube-hover-bgtop); */
    object-fit: cover;
    padding: 5px;
    /* justify-self: center; */
}
.product-card-hover{
    background-color: var(--cube-hover-bgtop);
    color: var(--bg-inner);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    padding: inherit;
    font-size: 1.1vw;
    transition: all 0.2s ease-in-out;
}
@media (max-width: 767px) {
    .product-card-hover{
        font-size: 18px;
    }
    .product-card{
        min-height: 369px;
    }
}
@media (max-width: 575px) {
    .product-card-hover{
        font-size: 11px;
    }
}

.product-card:hover .product-card-hover{
opacity: 1;
flex-direction: column;
align-items: start;
flex-wrap: wrap;
max-width: 100%;
}
.product-card-hover p{
    margin-bottom: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.product-card-hover .desc{
    text-align: left;
}

