ul.breadcrumb {
    list-style: none;
    background-color: var(--main-color);
  }
  ul.breadcrumb a {
    display: inline;
    font-size: 18px;
  }
  ul.breadcrumb p {
    display: inline;
    font-size: 18px;
  }
  @media (max-width: 455px) {
    ul.breadcrumb a {
    font-size: 11px;
  }
  ul.breadcrumb p {
    font-size: 11px;
  }
}
  @media (max-width: 320px) {
    ul.breadcrumb a {
    font-size: 9px;
    font-weight: bold;
  }
  ul.breadcrumb p {
    font-size: 9px;
    font-weight: bold;

  }
}
  ul.breadcrumb a+a:before {
    padding: 8px;
    color: var(--main-text-color);
    content: "\003E";
  }
  ul.breadcrumb p:before {
    padding: 8px;
    color: var(--main-text-color);
    content: "\003E";
  }
  ul.breadcrumb  a {
    color: var(--main-text-color);
    text-decoration: none;
  }
  .current-page{
      color: rgb(104, 148, 156) !important; ;
      
    }
    ul.breadcrumb  a:hover , .current-page:hover {
      color: #108bb1;
      
    }