.loader path:not(:first-of-type) , .st5{
    transition-duration: 1s;
    stroke: #fff;
    stroke-width: 1.25px;
    opacity:0;
    stroke-dasharray:736 ;
    animation: csvg 5s  linear  forwards;

    
}


@keyframes csvg {
    0%{
        opacity: 0;
        fill: none;
        stroke-dasharray: 730;
    }
    15%{
        opacity: 0.20;
        fill: none;
        stroke-dasharray:500 ;
    }
    40%{
        opacity: 0.6;
    }
   60%{
    opacity: 1;
        stroke-dasharray: 0;
    }
   100%{
    opacity: 1;
        stroke-dasharray: 0;
    }
  
    
}
