.catalogus-container{
    background-color: var(--main-color);
    position: relative;

}

.catalogus-container .container a{
    color: var(--main-text-color);
    text-decoration: none;
    
}

.catalogus-container .tool-check{
    display: flex  ;
}
.catalogus-container .product-card{
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.catalogus-container .product-card:hover{
   scale: 1.02; 
}


.catalogus-container .button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 8px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1vw;
    transition: all .15s ease;
  }
  @media (max-width: 700px) {
.catalogus-container .button{
    display: none;
}

  }
  
  .catalogus-container .button::before,
  .catalogus-container .button::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    height: calc(50% - 5px);
    border: 1px solid #7D8082;
    transition: all .15s ease;
  }
  
  .catalogus-container .button::before {
    top: 0;
    border-bottom-width: 0;
  }
  
  .catalogus-container .button::after {
    bottom: 0;
    border-top-width: 0;
  }
  
  .catalogus-container .button:active,
  .catalogus-container .button:focus {
    outline: none;
  }
  
  .catalogus-container .button:active::before,
  .catalogus-container .button:active::after {
    right: 3px;
    left: 3px;
  }
  
  .catalogus-container .button:active::before {
    top: 3px;
  }
  
  .catalogus-container .button:active::after {
    bottom: 3px;
  }
  
  .catalogus-container .button_lg {
    position: relative;
    display: block;
    padding: 10px 20px;
    color: #fff;
    background-color: var(--cube-hover-bgtop);
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px transparent;
  }
  
  .catalogus-container .button_lg::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
    background-color: #0f1923;
  }
  
  .catalogus-container .button_lg::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 4px;
    height: 4px;
    background-color: #0f1923;
    transition: all .2s ease;
  }
  
  .catalogus-container .button_sl {
    display: block;
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -8px;
    width: 0;
    background-color: var(--bg-inner);
    transform: skew(-15deg);
    transition: all .2s ease;
  }
  
  .catalogus-container .button_text {
    position: relative;
    color: var(--main-text-color);

  }
  
  .catalogus-container .button:hover {
    color: #0f1923;
  }
  .catalogus-container .button:hover .button_text  {
    color: var(--cube-hover-bgtop);

  }
  
  .catalogus-container .button:hover .button_sl {
    width: calc(100% + 15px);
  }
  
  .catalogus-container .button:hover .button_lg::after {
    background-color: #fff;
  }
  

  .gif {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
}