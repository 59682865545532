.hero-container{
    background-color: var(--main-color);
    color: var(--main-text-color);
    height: calc(100vh - 194px);
}

.hero-container .container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    background-color: var(--main-color);
}

.btnn {
    display: block;
    padding: 0.7em 1em;
    background: transparent;
    outline: none;
    border: 0;
    color: var(--main-text-color);
    letter-spacing: 0.1em;
    font-family: monospace;
    font-size: 2.6vw;
    font-weight: bold;
    z-index: 1;
    text-decoration: none !important;
   }
   
   .cube {
    position: relative;
    transition: all 0.5s;
   }
   
   .cube .bg-top {
    position: absolute;
    height: 10px;
    background:  var(--cube-bgtop);
    bottom: 100%;
    left: 5px;
    right: -5px;
    transform: skew(-45deg, 0);
    margin: 0;
    transition: all 0.4s;
   }
   
   .cube .bg-top .bg-inner {
    bottom: 0;
   }
   
   .cube .bg {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background:  var(--cube-bgtop);
    transition: all 0.4s;
   }
   
   .cube .bg-right {
    position: absolute;
    background:  var(--cube-bgtop);
    top: -5px;
    z-index: 0;
    bottom: 5px;
    width: 10px;
    left: 100%;
    transform: skew(0, -45deg);
    transition: all 0.4s;
   }
   
   .cube .bg-right .bg-inner {
    left: 0;
   }
   
   .cube .bg-inner {
    background: var(--cube-hover-bgtop);
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
   }
   
   .cube .text {
    position: relative;
    transition: all 0.4s;
   }
   
   .cube:hover .bg-inner {
    background: var(--bg-inner);
    transition: all 0.4s;
   }
   
   .cube:hover .text {
    color: var(--cube-hover-bgtop);
    transition: all 0.4s;
   }
   
   .cube:hover .bg-right,
   .cube:hover .bg,
   .cube:hover .bg-top {
    background: var(--cube-hover-bgtop);
   }
   
   .cube:active {
    z-index: 9999;
    animation: bounce .1s linear;
   }
   
   @keyframes bounce {
    50% {
     transform: scale(0.9);
    }
   }



   





.gear{
    position: relative;
    height: 100px;
    width: 100px;
}


   i {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    
    opacity: 0.2;
    background: url(http://i.imgur.com/lOBxb.png);
    animation: barrelRoll 5s infinite linear;
    animation-play-state: running;
}
i:first-of-type{
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}
i:last-of-type {
    top: 22px;
    left: 56px;
    top: 25%;
    left: 43%;
    transform: translate(-50%);
    animation-name: invertBarrelRoll;
}

.gear:hover > i {
    animation-play-state: running;
}

@keyframes barrelRoll {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes invertBarrelRoll {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(-360deg); }
}