.overons{
    background-color: var(--main-color);
    color: var(--main-text-color);

}
.overons p{
    font-size: 15px;
}

.overons h1{
    font-size: 7vw;
    color: var(--bg-inner);
    transform: rotate(180deg) translate(-4px);
  writing-mode: vertical-lr;
  border-right: 1px solid var(--bg-inner);
    border-top: 1px solid var(--bg-inner);
    background-color: var(--cube-hover-bgtop);
    padding: 5px;
}
