* , *::after , *::before{
  margin: 0;
  padding: 0;
/* overflow: hidden; */
}

:root{
  --main-color:black;
  --main-text-color:white;
  --hero-text-color:black;
  --cube-hover-bgtop: #28282d;
  --cube-bgtop: linear-gradient(#fff , #7a7373 , #979595 , #706e6e , #706e6e);
  --bg-inner: #ebe9e2;

}
.app{
  background-color: var(--main-color);
}

::-webkit-scrollbar{
  width: 8px;
  background-color: var(--cube-hover-bgtop);
}
::-webkit-scrollbar-thumb{
  width: 100%;
  background-color: var(--bg-inner);
  border-radius: 10px;
}